import testimonial from "../assets/Testimonial/testi.svg";
import testimonial2 from "../assets/Testimonial/testi2.svg";
import testimonial3 from "../assets/Testimonial/testi3.svg";
import Carousel from "./Carousel";
const   Testimonial = () => {
  return (
    <div
      id="pages"
      className="bg-[#F6FAF6] pb-5 h-[100vh] md:h-[800px] flex flex-col items-center"
    >
      <p
        data-aos="zoom-in-down"
        data-aos-duration="2000"
        className="md:text-[42px] text-[22px] leading-[31px] md:leading-[48px] font-jost font-semibold text-[#181B18] p-16 pb-0 text-center md:w-[636px] w-[380px]"
      >
        Trusted by <span className="text-[#21D57C]">10k+</span> Customers
      </p>
      {/* carousel of testamonil for mobile devices  */}
      <Carousel />

      {/* testamonil section for desktops  */}
      <div className="hidden md:flex md:flex-row md:justify-center md:gap-10 md:w-[80%] lg:mt-[80px] md:mt-10">
        {/* 1  */}
        <div
          data-aos="zoom-in-right"
          data-aos-duration="2000"
          className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center"
        >
          <img className="mt-[47px]" src={testimonial} alt="img1" />
          <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
            “LuvLawn transformed my lawn into a lush, green paradise! Their expertise and attention to detail are unmatched. 
            I highly recommend them for all your lawn care needs.”
          </p>
          <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
            Jenny Wilson
          </p>
          <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
            House Owner
          </p>
        </div>
        {/* 2 */}
        <div
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center shadow-[0px_34px_104px_rgba(18,22,28,0.1)]"
        >
          <img className="mt-[47px]" src={testimonial2} alt="img1" />
          <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
            “I'm thrilled with LuvLawn's service! My lawn looks healthier than ever, and the technicians are always professional and courteous. 
            Thank you, LuvLawn”
          </p>
          <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
          Michael S.
          </p>
          <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
            Office Manager
          </p>
        </div>
        {/* 3 */}
        <div
          data-aos="zoom-in-left"
          data-aos-duration="2000"
          className="bg-[#FFFFFF] lg:w-[372px] lg:h-[443px] md:w-[372px] md:h-[520px] rounded-[10px] flex flex-col items-center"
        >
          <img className="mt-[47px]" src={testimonial3} alt="img1" />
          <p className="font-normal font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[16px]">
            “LuvLawn's mosquito defense service is a game-changer! We can finally enjoy our backyard without worrying about pesky mosquitoes. 
            Thank you, LuvLawn, for making our outdoor space enjoyable again!”
          </p>
          <p className="font-semibold font-inter md:text-base text-center text-[#121D12] md:mx-5 md:mt-[31px]">
          Emily P
          </p>
          <p className="font-normal font-inter md:text-base text-center text-[#525B52] md:mx-5 md:mt-3">
            Government Officer
          </p>
        </div>
      </div>
     
    </div>
  );
};

export default Testimonial;
